<template>
  <div v-on:contextmenu.prevent="doNothing">
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <br>
            <p class="w-pagetitle" style="margin-left:20px;margin-right:20px; text-align:right;">
              <span v-on:click="gotoCellar()" class="w-cursor-click" ><u>{{cellar.name}}</u></span> 
              > {{niche.name}} 
            </p>
            <b-row class="justify-content-center">
              <p v-if="niche.code" class="t-title">overview of {{niche.code}}-{{niche.name}} which has {{niche.number_of_bottles}} bottles</p>
              <p v-else class="t-title">overview of {{niche.name}} which has {{niche.number_of_bottles}} bottles</p>
            </b-row>
            <!-- <b-row class="justify-content-center">
              <p class="t-subtitle">{{niche_id}}</p>
            </b-row> -->
            <b-row class="justify-content-center">
              <p class="t-subtitle">to select one, click - to add one, click on the plus sign</p>
            </b-row>    
            <b-row class="justify-content-center" style="margin:0">
              <b-col 
                  class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                  xs="12" sm="6" md="4" lg="2" xl="2"
                  v-on:click="onEdit()"
                  style ="display:block; margin:20px !important"
              >
                <b-img
                  width=300px
                  height=200px
                  :src="require('@/assets/img/plus.png')"
                >
                </b-img>
              </b-col>
              
              <b-col
                v-for="(entry, index) in entries"
                v-bind:item="entry"
                v-bind:index="index"
                v-bind:key="entry._id" 
                class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                xs="12" sm="6" md="4" lg="2" xl="2"
                style ="display:block; min-width:300px; margin:20px !important"
              >
                <b-row 
                  style="width:100%"
                  v-on:click="onSelect(entry._id)"
                >
                  <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                    <b-img center v-if="entry.image_files[0]"
                      style="height:100%;width:auto;position:absolute;right:0px"
                      v-bind:src="entry.image_files[0].dataURL" 
                    ></b-img>
                    <div v-else>
                      <b-img center v-if="entry.color == 'Red'"
                        style="height:100%;width:auto;position:absolute;right:0px"
                        :src="require('@/assets/img/bottle_red.png')"
                      ></b-img>
                      <b-img center v-else-if="entry.color == 'White'"
                        style="height:100%;width:auto;position:absolute;right:0px"
                        :src="require('@/assets/img/bottle_white.png')"
                      ></b-img>
                      <b-img center v-else-if="entry.color == 'Rosé'"
                        style="height:100%;width:auto;position:absolute;right:0px"
                        :src="require('@/assets/img/bottle_rose.png')"
                      ></b-img>
                      <b-img center v-else
                        style="height:100%;width:auto;position:absolute;right:0px"
                        :src="require('@/assets/img/bottle.png')"
                      ></b-img>
                    </div>
                    <!-- <b-img center v-else
                      style="height:100%;width:auto;position:absolute;right:0px"
                      :src="require('@/assets/img/bottle_red.png')"
                    ></b-img> -->
                  </b-col>
                  <b-col cols="9" class="d-table-cell">
                    <div>
                      <p class="t-title">{{entry.vintage}}</p>
                      <p class="t-title2">{{entry.name}}</p>
                      <p class="t-subtitle" v-if="entry.type">{{entry.type}}</p>
                      <p class="t-subtitle" style="color:white" v-else>bogus text here</p>
                      <hr style="margin:5px 0 5px 0">
                      <p class="t-text" v-if="entry.appellation">{{entry.appellation}}</p>
                      <div v-else>
                        <p class="t-text" v-if="entry.region">{{entry.region}}</p>
                        <p class="t-text" v-else>{{entry.country}}</p>
                      </div>
                      <p class="t-smalltext" v-if="entry.classification">{{entry.classification}}</p>
                      <div v-if="entry.grapes">
                        <p class="t-smalltext" v-if="entry.grapes.length < 95">
                          <b>grapes: </b>{{entry.grapes}}
                        </p>
                        <p class="t-smalltext" v-else>
                          <b>grapes: </b>{{entry.grapes.substring(0, 95)}}...
                        </p>
                      </div>
                      <p class="c-bottles">{{entry.number}}</p>
                    </div>
                    <!-- <div class="edit-symbol">
                      <b-img
                        src = "../../img/edit.png"
                        style="height:100%;width:100%"
                        v-on:click.stop="onEdit(entry._id)"
                        v-b-tooltip.hover  class="w-tooltip" title="edit entry"
                      />
                    </div> -->
                    <!-- <div v-if="entry.number == 0" class="delete-symbol">
                      <b-img
                        src = "../../img/delete.png"
                        style="height:100%;width:100%"
                        v-on:click.stop="onDelete(index)"
                        v-b-tooltip.hover class="w-tooltip" title="delete entry"
                      />
                    </div> -->
                    <b-button pill
                      class="edit-button"
                      v-on:click.stop="onEdit(entry._id)"
                      v-b-tooltip.hover title="edit entry"
                    >
                      <font-awesome-icon class="edit-symbol" icon="edit" />
                    </b-button>
                    <b-button pill
                      v-if="entry.number == 0"
                      class="archive-button"
                      v-on:click.stop="onArchive(index)"
                      v-b-tooltip.hover title="archive entry"
                    >
                      <font-awesome-icon class="archive-symbol" icon="archive" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col  class="text-left" style="margin-left:30px">
                <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
              </b-col>
            </b-row>
            <br>
          </b-col>
        </b-row>
        <br>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>

    <b-modal id="deleteEntry" ref="deleteEntry" title="delete entry"> @ok="deleteEntry">
      <b-row v-if="current_index >=0">
        <div style="width:300px;margin-left:auto;margin-right:auto">
          <b-col
            class="w-tile m-2 d-inline-flex justify-content-center"
            style ="display:block; margin:20px !important"
          >
            <b-row 
              style="width:100%"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <b-img center v-if="entries[current_index].image_files[0]"
                  style="height:100%;width:auto;position:absolute;right:0px"
                  v-bind:src="entries[current_index].image_files[0].dataURL" 
                ></b-img>
                <!-- <b-img center v-else
                  style="height:100%;width:auto;position:absolute;right:0px"
                  :src="require('@/assets/img/bottle.png')"
                ></b-img> -->
                <div v-else>
                  <b-img center v-if="entries[current_index].color == 'Red'"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle_red.png')"
                  ></b-img>
                  <b-img center v-else-if="entries[current_index].color == 'White'"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle_white.png')"
                  ></b-img>
                  <b-img center v-else-if="entries[current_index].color == 'Rosé'"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle_rose.png')"
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
                </div>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <div>
                  <p class="t-title">{{entries[current_index].vintage}}</p>
                  <p class="t-title2">{{entries[current_index].name}}</p>
                  <p class="t-subtitle" v-if="entries[current_index].type">{{entries[current_index].type}}</p>
                  <p class="t-subtitle" style="color:white" v-else>bogus text here</p>
                  <hr>
                  <p class="t-text" v-if="entries[current_index].appellation">{{entries[current_index].appellation}}</p>
                  <p class="t-text" v-if="entries[current_index].classification">{{entries[current_index].classification}}</p>
                  <p class="c-bottles">{{entries[current_index].number}}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </b-row>
      <p class="t-title text-center">ARE YOU SHURE YOU WANT TO DELETE THIS ENTRY?</p>
      
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal id="archiveEntry" ref="archiveEntry" title="archive entry" @ok="archiveEntry">
      <b-row v-if="current_index >=0">
        <div style="width:300px;margin-left:auto;margin-right:auto">
          <b-col
            class="w-tile m-2 d-inline-flex justify-content-center"
            style ="display:block; margin:20px !important"
          >
            <b-row 
              style="width:100%"
            >
              <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                <b-img center v-if="entries[current_index].image_files[0]"
                  style="height:100%;width:auto;position:absolute;right:0px"
                  v-bind:src="entries[current_index].image_files[0].dataURL" 
                ></b-img>
                <!-- <b-img center v-else
                  style="height:100%;width:auto;position:absolute;right:0px"
                  :src="require('@/assets/img/bottle.png')"
                ></b-img> -->
                <div v-else>
                  <b-img center v-if="entries[current_index].color == 'Red'"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle_red.png')"
                  ></b-img>
                  <b-img center v-else-if="entries[current_index].color == 'White'"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle_white.png')"
                  ></b-img>
                  <b-img center v-else-if="entries[current_index].color == 'Rosé'"
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle_rose.png')"
                  ></b-img>
                  <b-img center v-else
                    style="height:100%;width:auto;position:absolute;right:0px"
                    :src="require('@/assets/img/bottle.png')"
                  ></b-img>
                </div>
              </b-col>
              <b-col cols="9" class="d-table-cell">
                <div>
                  <p class="t-title">{{entries[current_index].vintage}}</p>
                  <p class="t-title2">{{entries[current_index].name}}</p>
                  <p class="t-subtitle" v-if="entries[current_index].type">{{entries[current_index].type}}</p>
                  <p class="t-subtitle" style="color:white" v-else>bogus text here</p>
                  <hr>
                  <p class="t-text" v-if="entries[current_index].appellation">{{entries[current_index].appellation}}</p>
                  <p class="t-text" v-if="entries[current_index].classification">{{entries[current_index].classification}}</p>
                  <p class="c-bottles">{{entries[current_index].number}}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </b-row>
      <p class="t-title text-center">ARE YOU SHURE YOU WANT TO ARCHIVE THIS ENTRY?</p>
      
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue';
  import Menu from './MenuDesktop.vue';

  import Cellars from '../Cellars.js'
  import Niches from '../Niches.js'
  import Entries from '../Entries.js'

  export default {
    name: "NicheShow",
    components: {
      NoAccess,
      Menu,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      },

      currentCellarID() {
        return this.$store.state.cellar_id
      },
      currentCellar() {
        return this.$store.state.cellar
      },
      currentCellarLoaded() {
        return this.$store.state.cellar_loaded
      }
    },
    data() {
      return {
        debug: false,
        
        isLoading : true,

        cellar: {},
        cellar_id:0,
        
        niche: {
          name : '',
          code : '',
          image_files: [],
          entry_ids: [],
          cellar_id:0,
        },
        niche_id :0,

        entries: [],
        error: '',
        isValid: true,

        current_index: -1,
      }
    }, 
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log( "NicheShow.mounted: START")

      // Load the Cellar
      if (this.currentCellarLoaded) {
        if (this.debug) console.log("NicheShow.mounted: CURRENT CELLAR LOADED")
        this.cellar = this.currentCellar
        this.cellar_id = this.currentCellarID
        if (this.debug) console.log("NicheShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully retrieved from store.")
      }
      else {
        if (this.debug) console.log("NicheShow.mounted: CURRENT CELLAR NOT LOADED - QUERYING THE DATABASE")
        this.cellar_id = this.$route.query.cellar_id
        await Cellars.getone(this.cellar_id)
        .then(response => {
          this.cellar = response
          delete this.cellar._id
          if (this.debug) console.log("NicheShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully fetched from DB.")
        })
        .catch(error =>{
          if (this.debug) console.log("NicheShow.mounted: catch Cellar.getone with error: ", error)
        })

        this.$store.commit('setCellar',this.cellar)
        this.$store.commit('setCellarID',this.cellar_id)
        this.$store.commit('setCellarLoaded',true)

        if (this.debug) console.log("NicheShow.mounted: cellar ", this.cellar, " with id ", this.cellar_id, " succesfully stored in store.")
      }

      // Load the niche
      this.niche_id = this.$route.query.niche_id;
      if (typeof this.niche_id !== "undefined") {
        await Niches.getone(this.niche_id)
        .then(response=> {
          this.niche = response;
          delete this.niche._id;
          if (this.debug) console.log( "NicheShow.mounted: Niche ", this.niche, " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("NicheShow.mounted: await Niches", error);
        })
      } else {
        if (this.debug) console.log("NicheShow.mounted: ERROR this.niche_id = ", this.niche_id);
      }
      
      if (typeof this.niche.entry_ids.length !== 0) {
        for (let i=0; i < this.niche.entry_ids.length; i++) {
          await Entries.getone(this.niche.entry_ids[i])
          .then(response=> {
            this.entries.push(response);
            if (this.debug) console.log( "NicheShow.mounted: Entry ", this.entries[i], " succesfully fetched from DB");
          })
          .catch(error=> {
            if (this.debug) console.log("NicheShow.mounted: await Entries i=", i , " ", error);
          })
        }
        this.entries.sort(function(a, b) {
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        });
      }

      await this.recalcNumber()
      this.isLoading = false
      if (this.debug) console.log( "NicheShow.mounted: STOP")
    },
    methods: {
      onSelect(entry_id) {
        if (this.debug) console.log("NicheShow.onSelect: entry_id = ", entry_id);
        this.$store.commit('setPage','EntryShow')
        this.$router.push({path:'/EntryShow', query: {cellar_id:this.$route.query.cellar_id,niche_id:this.$route.query.niche_id, entry_id:entry_id }});
      },
      onEdit(entry_id){
        if (this.debug) console.log("NicheShow.onEdit: entry_id = ", entry_id);
        this.$store.commit('setPage','EntryEdit')
        if (typeof entry_id === "undefined") {
          this.$router.push({path:'/EntryEdit', query: {cellar_id:this.$route.query.cellar_id,niche_id:this.$route.query.niche_id}});
        } else {
          this.$router.push({path:'/EntryEdit', query: {cellar_id:this.$route.query.cellar_id,niche_id:this.$route.query.niche_id, entry_id:entry_id }});
        }
      },
      async onDelete(i) {
        this.current_index = i
        if (this.debug) console.log("Delete Pressed i = "+ i)
        await this.$bvModal.show('deleteEntry')
      },
      async onArchive(i) {
        this.current_index = i
        if (this.debug) console.log("Archive Pressed i = "+ i)
        await this.$bvModal.show('archiveEntry')
      },
      
      async deleteEntry(){
        if (this.debug) console.log("")
        if (this.debug) console.log("NicheShow.deleteEntry: START")
        let this_entry_id = this.entries[this.current_index]._id
        if (this.debug) console.log("NicheShow.deleteEntry: this_entry_id=",this_entry_id)

        await Entries.delete(this_entry_id)
        .then(response => {
          if (this.debug) console.log("NicheShow.deleteEntry: entry with id ", this_entry_id, " deleted with response : ", response)
        })
        .catch(error => {
          if (this.debug) console.log('NicheShow.deleteEntry: catch await Entries.delete ERROR : ' + error);
        })

        // Update niche in the database
        for (let i=0 ; i < this.niche.entry_ids.length ; i++) {
          if (this.niche.entry_ids[i] == this_entry_id) {
            this.niche.entry_ids.splice(i,1)
            break
          }
        }
        if (this.debug) console.log("NicheShow.deleteEntry: entry with id ", this_entry_id, " removed from this.niche.entry_ids")

        this.recalcNumber()
        
        await Niches.update(this.niche_id,this.niche)
        .then(response => {
          if (this.debug) console.log("NicheShow.deleteEntry: niche ",this.niche," with id ", this.niche_id, " updated with response : ", response)
        })
        .catch(error => {
          if (this.debug) console.log('NicheShow.deleteEntry: catch await Niches.update ERROR : ' + error);
        })

        // Update cellar in the database
        for (let i=0 ; i < this.cellar.entry_ids.length ; i++) {
          if (this.cellar.entry_ids[i] == this_entry_id) {
            this.cellar.entry_ids.splice(i,1)
            break
          }
        }
        if (this.debug) console.log("NicheShow.deleteEntry: entry with id ", this_entry_id, " removed from this.cellar.entry_ids")

        await Cellars.update(this.cellar_id,this.cellar)
        .then(response => {
          if (this.debug) console.log("NicheShow.deleteEntry: cellar ",this.cellar," with id ", this.cellar_id, " updated in DB with response : ", response)
        })
        .catch(error => {
          if (this.debug) console.log('NicheShow.deleteEntry: catch await Cellars.update ERROR : ' + error);
        })

        // Update cellar in the store
        this.$store.commit('setCellar',this.cellar)
        if (this.debug) console.log("Cellar.deleteNiche: cellar ", this.cellar_id, " updated in store")

        // Update Entries
        if (this.debug) console.log("NicheShow.deleteEntry: entry with id ", this.entries[this.current_index]._id, " removed from entries")
        this.entries.splice(this.current_index,1);
        this.current_index = -1;

        if (this.debug) console.log("NicheShow.deleteEntry: STOP")
      },

      async archiveEntry(){
        let local_debug = false
        if (local_debug || this.debug) console.log("")
        if (local_debug || this.debug) console.log("NicheShow.archiveEntry: START")
        let this_entry_id = this.entries[this.current_index]._id
        let this_entry = this.entries[this.current_index]
        delete this_entry._id
        if (local_debug || this.debug) console.log("NicheShow.archiveEntry: this_entry_id=",this_entry_id," this_entry=",this_entry)

        // Remove Entry from niche and update niche in the database
        for (let i=0 ; i < this.niche.entry_ids.length ; i++) {
          if (this.niche.entry_ids[i] == this_entry_id) {
            this.niche.entry_ids.splice(i,1)
            break
          }
        }
        if (local_debug || this.debug) console.log("NicheShow.archiveEntry: entry with id ", this_entry_id, " removed from this.niche.entry_ids")

        this.recalcNumber()

        await Niches.update(this.niche_id,this.niche)
        .then(response => {
          if (local_debug || this.debug) console.log("NicheShow.archiveEntry: niche ",this.niche," with id ", this.niche_id, " updated with response : ", response)
        })
        .catch(error => {
          if (local_debug || this.debug) console.log('NicheShow.archiveEntry: catch await Niches.update ERROR : ' + error);
        })

        // Update Entries
        if (local_debug || this.debug) console.log("NicheShow.archiveEntry: entry with id ", this.entries[this.current_index]._id, " removed from entries")
        this.entries.splice(this.current_index,1);
        this.current_index = -1;

        // Remove Entry from cellar.entry_ids
        for (let i=0 ; i < this.cellar.entry_ids.length ; i++) {
          if (this.cellar.entry_ids[i] == this_entry_id) {
            this.cellar.entry_ids.splice(i,1)
            break
          }
        }
        if (local_debug || this.debug) console.log("NicheShow.archiveEntry: entry with id ", this_entry_id, " removed from this.cellar.entry_ids")        

        // Put Entry in archive and update the cellar in DB
        this.cellar.archive_ids.push(this_entry_id)
        
        await Cellars.update(this.cellar_id,this.cellar)
        .then(response => {
          if (local_debug || this.debug) console.log("NicheShow.archiveEntry: cellar ",this.cellar," with id ", this.cellar_id, " updated in DB with response : ", response)
        })
        .catch(error => {
          if (local_debug || this.debug) console.log('NicheShow.archiveEntry: catch await Cellars.update ERROR : ' + error);
        })

        // Update cellar in the store
        this.$store.commit('setCellar',this.cellar)
        if (local_debug || this.debug) console.log("Cellar.archiveEntry: cellar ", this.cellar_id, " updated in store")

        // Update entry in DB
        this_entry.niche_id = "archived"
        let today = new Date();
        this_entry.archived_date = today.getFullYear()+'-'+(today.getMonth() + 1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2, "0");

        await Entries.update(this_entry_id,this_entry)
        .then(response => {
          if (local_debug || this.debug) console.log("NicheShow.archiveEntry: entry ",this_entry," with id ", this_entry_id, " updated with response : ", response)
        })
        .catch(error => {
          if (local_debug || this.debug) console.log('NicheShow.archiveEntry: catch await Entries.update ERROR : ' + error);
        })

        if (local_debug || this.debug) console.log("NicheShow.archiveEntry: STOP")
      },  

      onBack() {
        this.$store.commit('setPage','Cellar')
        this.$router.push({path:'/Cellar', query: {cellar_id:this.$route.query.cellar_id }});
      },

      gotoCellar() {
        this.$store.commit('setPage','Cellar')
        this.$router.push({path:'/Cellar', query: {cellar_id:this.$route.query.cellar_id }} );
      },

      async recalcNumber() {
        let sum = 0;
        for (let i=0; i < this.entries.length; i++) {
          if (typeof this.entries[i].number == "number") {
            sum += this.entries[i].number;
          } else {
            sum += parseInt(this.entries[i].number);
          }
        }
        if (this.debug) console.log("=======> NicheShow.recalcNumber:  sum = ", sum);
        this.niche.number_of_bottles = sum;
        await Niches.update(this.niche_id,this.niche)
        .then(response=>{
          if (this.debug) console.log("=======> NicheShow.recalcNumber:  succesfully updated Niche ",this.niche," with response ", response);
        })
        .catch(error=>{
          if (this.debug) console.log("=======> NicheShow.recalcNumber:  catch await Niche.update ERROR :", error);
        })
      },
      
      doNothing() {
      }, 
    }
  }    
</script>

<style scoped>
.w-bottomright {
  position:absolute;
  bottom: 10px;
  right: 5px;
  margin:0;
}
.w-big {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.w-text {
  font-size: 10px;
  margin:0;
}
</style> 