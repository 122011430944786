<template>
<div>
  <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
  </b-container>
  <div v-else>
    <div class="w-menu">
      <br>
      <b-row v-if="this.currentDatabase.name=='Test'" style="position:relative; bottom:310px">
        <p class="w-subtitle">{{currentDatabase.name}}</p>
      </b-row>
      <b-row class="w-menu-avatar w-cursor-click" @click="gotoProfile()">
        <b-col>
          <b-avatar v-if="currentProfilePicture" 
            size="50px" 
            v-bind:src="currentProfilePicture"
          >
          </b-avatar>
          <b-avatar v-else 
            size="50px" 
            :src="require('@/assets/img/profile-picture.jpg')"
          >
          </b-avatar>
        </b-col>
      </b-row>
      <b-row class="w-menu-under-avatar">
        <b-col style="padding:0" class="text-center">
            <p class="w-menu-name">{{currentUser.firstname}} {{currentUser.lastname}}</p>
            <p class="w-menu-email">{{currentUser.email}}</p>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row justify-content-center" style="width:100%; margin:0">  
        <div class="justify-content-left">
          <p class="w-menuitem-1" style="top:120px;color:white">cellar </p>
          <p class="w-menuitem-3 w-cursor-click" style="top:157px" @click="gotoDashboard()">cellar</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:194px" @click="gotoDashboard()">dashboard</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:231px" @click="gotoCellar()">content</p>
          <p class="w-menuitem-2 w-cursor-click" style="top:268px" @click="gotoSearch()">search</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:305px" @click="gotoLibrary()">library</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:342px" @click="gotoWishlist()">wishlist</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:379px" @click="gotoArchive()">archive</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:416px" @click="gotoAboutUs()">about us</p>
          <p class="w-menuitem-3 w-cursor-click" style="top:453px" @click="logOut()">log off</p>
        </div>
        <div v-if="this.currentPage=='Dashboard'" class="w-current-item" style="top:192px"/>
        <div v-if="this.currentPage=='Cellar'" class="w-current-item" style="top:229px"/>
        <div v-if="this.currentPage=='Search'" class="w-current-item" style="top:266px"/>
        <div v-if="this.currentPage=='Library'" class="w-current-item" style="top:303px"/>
        <div v-if="this.currentPage=='Wishlist'" class="w-current-item" style="top:340px"/>
        <div v-if="this.currentPage=='Archive'" class="w-current-item" style="top:377px"/>
        <div v-if="this.currentPage=='AboutUs'" class="w-current-item" style="top:314px"/>
      </b-row>
      <div class="w-menu-footer">
        <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>CELLAR</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    computed : {
      currentDatabase() {
        return this.$store.state.database
      },
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      currentPage() {
        return this.$store.state.current_page
      },
      previousPage() {
        return this.$store.state.previous_page
      }
    },
    data() {
      return {
        isLoading : true,
      }
    },
    async mounted() {
      this.isLoading = false
    },
    methods:{
      gotoProfile() {
        this.$store.commit('setPage','Profile')
        this.$router.push({path:'/Profile', query: {cellar_id:this.$route.query.cellar_id}})
      },
      gotoDashboard() {
        if (this.currentPage != 'Dashboard') {
          this.$store.commit('setPage','Dashboard')
          this.$router.push({path:'/Dashboard', query: {cellar_id:this.$route.query.cellar_id }} );
        }
      },
      gotoCellar() {
        this.$store.commit('setSearchState',{})
        this.$store.commit('setPage','Cellar')
        this.$router.push({path:'/Cellar', query: {cellar_id:this.$route.query.cellar_id }} );
      },
      gotoTastings() {
        this.$store.commit('setPage','Tastings')
        this.$router.push({path:'/Tastings', query: {cellar_id:this.$route.query.cellar_id }} );
      },
      gotoSearch() {
        this.$store.commit('setSearchState',{})
        this.$store.commit('setPage','Search')
        this.$router.push({path:'/Search', query: {cellar_id:this.$route.query.cellar_id }} );
      },
      gotoLibrary() {
        this.$store.commit('setSearchState',{})
        this.$store.commit('setPage','Library')
        this.$router.push({path:'/Library', query: {cellar_id:this.$route.query.cellar_id }} );
      },
      gotoWishlist() {
        this.$store.commit('setPage','Wishlist')
        this.$router.push({path:'/Wishlist', query: {cellar_id:this.$route.query.cellar_id }} );
      },
      gotoArchive() {
        this.$store.commit('setPage','Archive')
        this.$router.push({path:'/Archive', query: {cellar_id:this.$route.query.cellar_id }} );
      },
      gotoAboutUs() {
        this.$store.commit('setPage','AboutUs')
        this.$router.push({path:'/AboutUs'});
      },
      logOut() {
        this.$store.commit('logout')
        this.$store.commit('setCellarLoaded', false)
        this.$router.push({path:'/'})
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
</style>